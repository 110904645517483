<template>
  <div class="k-9sptci" v-loading="loading">
    <el-form :inline="true" class="k-9sptci-header">
      <el-form-item>
        <el-button type="success" @click="searchClick">查询</el-button>
      </el-form-item>
      <perContainer :perm="`armory:${type}:save`">
        <el-form-item>
          <el-popover placement="bottom" width="400" trigger="click">
            <el-upload
              class="upload-demo"
              ref="upload"
              :multiple="false"
              action="#"
              :http-request="httpUploadRequest"
              :auto-upload="true"
              v-loading="loading"
            >
              <el-button slot="trigger" size="small" type="primary"
                >选取文件</el-button
              >
              <!--            <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>-->
            </el-upload>
            <el-button
              v-loading="loading"
              size="small"
              type="warning"
              slot="reference"
              @click.native.stop=""
              >上传资源</el-button
            >
          </el-popover>
        </el-form-item>
      </perContainer>
    </el-form>
    <el-table :data="dataTable" style="margin-top:20px" border>
      <template v-for="item in dataList">
        <el-table-column
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
        </el-table-column>
      </template>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <el-button @click.native.stop="handlePreview(scope.row)"
            >预览</el-button
          >
          <popconfirmButton
            :perm="`oss_assets`"
            content="是否确认删除？"
            text="删除"
            @onConfirm="handleDelete(scope.row)"
            style="margin-left: 10px"
          ></popconfirmButton>
        </template>
      </el-table-column>
    </el-table>
    <div class="k-9sptci-footer" v-if="hasMore">
      <el-link @click="handleQuery">更多</el-link>
    </div>

    <el-dialog
      :title="'预览'"
      :visible.sync="previewVisible"
      width="fit-content"
      @closed="previewData = false"
      v-if="previewData"
    >
      <img :src="previewData.url" style="height: 200px; width: fit-content" />
    </el-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import $http from "@/http/http";
export default {
  name: "match_assets_manager",
  components: {},
  props: {},
  data() {
    let self = this;
    return {
      ids: [],
      type: "referee",
      loading: false,
      previewVisible: false,
      previewData: false,
      dataTable: [],
      start: "",
      hasMore: false,
      dataList: [
        {
          prop: "name",
          label: "资源",
          width: 500,
        },
        {
          prop: "size",
          label: "大小",
          width: 300,
        },
        {
          prop: "time",
          label: "更新时间",
          width: 300,
        },
      ],
    };
  },
  computed: {},
  methods: {
    onPreviewClosed() {
      this.previewVisible = false;
      this.previewRefId = "";
    },
    async handlePreview(row) {
      if (
        row.name.indexOf(".png") >= 0 ||
        row.name.indexOf(".jpg") >= 0 ||
        row.name.indexOf(".jpeg") >= 0
      ) {
        this.previewVisible = true;
        this.previewData = row;
      } else {
        window.open(row.url);
      }
    },
    async handleDelete(row) {
      const { data, errorCode } = await $http({
        url: "/admin/match/delete_match_asset",
        method: "post",
        data: {
          name: row.name.replace(
            process.env.VUE_APP_MATCH_ASSETS_PATCH + "/",
            ""
          ),
        },
      });
      if (errorCode != "0000") return;
      this.start = "";
      this.searchClick();
    },
    searchClick() {
      this.start = "";
      this.dataTable = [];
      this.handleQuery();
    },
    async handleQuery() {
      console.log(process.env.VUE_APP_MATCH_ASSETS_PATCH);
      const { data, errorCode } = await $http({
        url: "/admin/match/match_assets",
        method: "get",
        data: {
          start: this.start,
        },
      });
      if (errorCode != "0000") return;
      if (data && data.objects) {
        data.objects.forEach((r) => {
          this.dataTable.push({
            name: r.name,
            size: this.formatFileSize(r.size),
            url: r.url,
            time:
              r.lastModified && r.lastModified.length
                ? new Date(r.lastModified).format("yyyy-MM-dd hh:mm:ss")
                : "",
          });
        });

        if (data.objects.length > 0) {
          this.start = data.objects[data.objects.length - 1].name;
        }
      }

      this.hasMore = data && data.objects && data.objects.length >= 10;
      console.log(data);
    },
    async httpUploadRequest(item) {
      this.loading = true;
      let fileName = item.file.name;
      let path = process.env.VUE_APP_MATCH_ASSETS_PATCH + "/" + fileName;
      console.log(path);
      let result = await this.$http.uploadFile(
        path,
        fileName,
        item.file,
        "no-cache"
      );
      if (result) {
        // this.dataForm.avatar = result;
        await this.$http.match.reload_config(path);
      } else {
        this.$message.error("上传失败");
      }
      this.loading = false;
    },
    formatFileSize(fileSize) {
      let temp = "";
      if (fileSize < 1024) {
        return fileSize + "B";
      } else if (fileSize < 1024 * 1024) {
        temp = fileSize / 1024;
        temp = temp.toFixed(2);
        return temp + "KB";
      } else if (fileSize < 1024 * 1024 * 1024) {
        temp = fileSize / (1024 * 1024);
        temp = temp.toFixed(2);
        return temp + "MB";
      } else {
        temp = fileSize / (1024 * 1024 * 1024);
        temp = temp.toFixed(2);
        return temp + "GB";
      }
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  async created() {
    this.handleQuery();
  },
  destroyed() {},
};
</script>
<style lang="less">
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}
</style>
